import React from "react";
import Card from "react-bootstrap/Card";

function Card3({ name, content }) {
  return (
    <Card className="card3" style={{ border: "2px solid #D2B978" }}>
      <Card.Body>
        <Card.Title>
          <i
            className="fas fa-star fa-7x fs-6 me-2"
            style={{ color: "#FED500" }}
          ></i>
          <i
            className="fas fa-star fa-7x fs-6 me-2"
            style={{ color: "#FED500" }}
          ></i>
          <i
            className="fas fa-star fa-7x fs-6 me-2"
            style={{ color: "#FED500" }}
          ></i>
          <i
            className="fas fa-star fa-7x fs-6 me-2"
            style={{ color: "#FED500" }}
          ></i>
          <i
            className="fas fa-star fa-7x fs-6 me-2"
            style={{ color: "#FED500" }}
          ></i>
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted my-3 fs-5">
          {name}
        </Card.Subtitle>
        <Card.Text>"{content}"</Card.Text>
      </Card.Body>

      <style jsx>{`
        .card3 {
          height: 100%;

          @media only screen and (max-width: 768px) {
            height: 250px;
            display: flex;
            justify-content: space-between;
          }
        }
      `}</style>
    </Card>
  );
}

export default Card3;
