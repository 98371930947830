import React from "react";
import NavBar from "../../components/navBar";
import { useNavigate } from "react-router-dom";
import { ButtonZap } from "../../Styles";
import LogoZap1 from "../../assets/icon-zap-1.png";


function Blog() {
  const navigate = useNavigate();

  function navigateContent1() {
    navigate("/blogContent1");
  }

  function navigateContent2() {
    navigate("/blogContent2");
  }

  function navigateContent3() {
    navigate("/blogContent3");
  }

  function navigateContent4() {
    navigate("/blogContent4");
  }

  function navigateContent5() {
    navigate("/blogContent5");
  }

  function navigateContent6() {
    navigate("/blogContent6");
  }

  function navigateContent7() {
    navigate("/blogContent7");
  }

  return (
    <container className="d-flex flex-column min-vh-100">
      <header
        style={{ position: "sticky", top: "0", zIndex: "1", opacity: 0.94 }}
      >
        <NavBar></NavBar>
      </header>

      <main className="container flex-grow-1">
        <di className="row">
          <div
            className="mt-5 text text-center display-6"
            style={{
              color: "#D2B978",
              fontFamily: "Libre Baskerville",
            }}
          >
            <h1>Blog | Mais informações:</h1>
          </div>
        </di>
        <div className="d-grid gap-3 my-5">
          <button
            onClick={navigateContent1}
            className="btn btn-outline-secondary fs-5 py-3"
          >
            Depressão
          </button>
          <button
            onClick={navigateContent2}
            className="btn btn-outline-secondary fs-5 py-3"
          >
            Autoestima
          </button>
          <button
            onClick={navigateContent3}
            className="btn btn-outline-secondary fs-5 py-3"
          >
            Como funciona as sessões?
          </button>
          <button
            onClick={navigateContent4}
            className="btn btn-outline-secondary fs-5 py-3"
          >
            Transtorno de Ansiedade Generalizada
          </button>
          <button
            onClick={navigateContent5}
            className="btn btn-outline-secondary fs-5 py-3"
          >
            Transtorno de Ansiedade Social
          </button>
          <button
            onClick={navigateContent6}
            className="btn btn-outline-secondary fs-5 py-3"
          >
            Terapia Cognitivo Comportamental
          </button>
          <button
            onClick={navigateContent7}
            className="btn btn-outline-secondary fs-5 py-3"
          >
            Transtorno Obsessivo Compulsivo
          </button>
        </div>
      </main>

      <footer
        className="mt-5 mt-lg-1 pb-1 pt-3"
        style={{ backgroundColor: "#011E44" }}
      >
        <p className="text text-light text-center" style={{ fontSize: "10px" }}>
          Copyright © 2024 – BRUNA CORREIA PSICÓLOGA | Todos os direitos
          reservados.
        </p>
      </footer>

      <ButtonZap
        href="https://api.whatsapp.com/send?1=pt_BR&phone=5531971220250"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={LogoZap1} alt="botão-whatsapp" />
      </ButtonZap>

      <style jsx>{`
        .btn-outline-secondary:hover {
          background-color: #011e44;
          border-color: #011e44;
          color: white;
        }
      `}</style>
    </container>
  );
}

export default Blog;
