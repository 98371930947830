import React from "react";
import NavBar from "../../../components/navBar";
import { Title1 } from "../../../Styles"

function BlogContent() {
  return (
    <container className="d-flex flex-column min-vh-100">
      <header
        style={{ position: "sticky", top: "0", zIndex: "1", opacity: 0.94 }}
      >
        <NavBar></NavBar>
      </header>
      
      <main className="container">
      <div className="row">
        <Title1 className="col text display-5 text-center py-5">
          Depressão
        </Title1>
      </div>
      <div className="row">
        <div className="col-11 mx-auto mb-5" style={{ textAlign: "justify" }}>
          <p className="col-12 text fs-5">
            Sentir tristeza é normal e faz parte da vida. A tristeza é
            passageira, sendo muitas vezes consequência de um fator adverso da
            vida, uma perda, um desapontamento, uma lembrança de algo ruim que
            aconteceu ou mesmo um pensamento não adequado que ativamos de forma
            automática.<br></br>
            <br></br> A depressão é um quadro complexo, que envolve fatores
            genéticos, psicológicos e ambientais em sua origem. Uma pessoa com
            depressão apresenta sensações abatimento, irritação, vazio. Além de
            afetar o humor, a depressão interfere nos pensamentos, nos
            comportamentos e nas reações físicas. Pode afetar o sono, a
            alimentação, a rotina, a energia, a capacidade de concentração, a
            capacidade de tomar decisões. A depressão afeta também os conteúdos
            do pensamento, levando a pessoa ter foco exagerado nos aspectos
            negativos, gerando sensações injustificadas de inutilidade,
            desvalorização e culpa.<br></br>
            <br></br> Apresentar um quadro de depressão não é um sinal de
            fraqueza ou uma condição que se possa facilmente reverter pela
            própria força de vontade. Uma pessoa com depressão, na maior parte
            das vezes, vai precisar de ajuda para conseguir reagir e enfrentar o
            problema para que possa ter uma melhora significativa.<br></br>
            <br></br>
            Pessoas depressivas com tendência a ansiedade podem sempre achar que
            algo de ruim irá acontecer. Ficam sempre se preparando para o pior.
            De acordo com a Terapia Cognitiva Comportamental (TCC), a nossa
            interpretação sobre uma situação influencia diretamente no que
            sentimos, em como agimos e nas nossas reações corporais. Uma pessoa
            que está em um quadro depressivo, tem uma maior tendência de avaliar
            as situações por um viés negativista e pessimista, que também é
            irrealista. Com isso, essa pessoa experimenta mais emoções
            desagradáveis e tende a apresentar mais comportamentos
            desadaptativos que acabam trazendo prejuízos e mantendo o ciclo do
            transtorno.
          </p>
          <Title1 className="col-12 text fs-3 pt-5 pb-4">
            Tendências de interpretação de pessoas em episódio depressivo.
          </Title1>
          <p className="col-12 text fs-5">
            <strong style={{ color: "#011E44" }}>
              Interpretações sobre os acontecimentos:
            </strong>{" "}
            Quando o paciente está deprimido ele tende a interpretar os
            acontecimentos de uma maneira pior do que interpretaria se não
            estivesse com o humor deprimido. Então, um problema que em estado de
            humor normal teria um determinado impacto, quando o paciente está
            deprimido esse problema passa a ter um impacto maior na vida do
            paciente. O paciente também acredita que se acontece algo de ruim,
            tudo o que acontecer terá um desfecho semelhante, tendendo a
            acreditar que tudo dará errado. Além disso, em episódios depressivos
            o paciente tende a desconsiderar ou minimizar acontecimentos
            positivos, o que o impede de perceber que coisas boas também
            acontecem na sua vida.
            <br></br>
            <br></br>
            <strong style={{ color: "#011E44" }}>
              Interpretações sobre a dimensão do tempo:
            </strong>{" "}
            Pessoas com depressão costumam acreditar que as causas dos
            acontecimentos indesejáveis serão permanentes e afetarão tudo que
            fizerem. Por isso, são pessoas que tendem a desistir ou não
            enfrentam desafios e isso as impedem de testar as crenças sobre os
            acontecimentos.
            <br></br>
            <br></br>
            <strong style={{ color: "#011E44" }}>
              Interpretações negativas sobre a responsabilidade:
            </strong>{" "}
            Pessoas com depressão costumam a atribuir a si mesmas, de forma não
            adequada, a maior parte da responsabilidade (culpa) pelos coisas
            ruins que acontecem. Além disso, costumam ser autodepreciativas,
            repetindo internamente falas negativas contra si. Desconsideram
            outras variáveis que podem afetar o desempenho em uma tarefa ou
            situação.
          </p>
          <Title1 className="col-12 text fs-3 pt-5 pb-4">
            Terapia Cognitiva Comportamental para Depressão
          </Title1>
          <p className="col-12 text fs-5">
            A TCC trabalha para ajudar o paciente na identificação os
            pensamentos que geram sentimentos desconfortáveis e comportamentos
            prejudiciais consequentes, além de contribuir para o entendimento
            das razões pelas quais costumamos pensar da maneira que pensamos. É
            possível questionar os pensamentos a ponto de modifica-los, caso
            estes não se sustentem por argumentos e lógicos e fatos observáveis.
            <br></br>
            <br></br> O objetivo é ajudar o paciente a desenvolver pensamentos
            mais realistas frente as situações vivenciadas. Dessa forma, é
            possível reduzir a intensidade de sentimentos desconfortáveis ou
            mesmo desenvolver sentimentos diferentes e consequentemente ter
            reações comportamentais menos prejudiciais.
          </p>
        </div>
      </div>
    </main>

      <footer
        className="mt-5 mt-lg-1 pb-1 pt-3"
        style={{ backgroundColor: "#011E44" }}
      >
        <p className="text text-light text-center" style={{ fontSize: "10px" }}>
          Copyright © 2024 – BRUNA CORREIA PSICÓLOGA | Todos os direitos
          reservados.
        </p>
      </footer>
    </container>
  );
}

export default BlogContent;
