import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";

function NavBar() {
  /*-------------------------------------NAVEGAÇÂO ENTRE PAGINAS---------------------------------------------*/
  const navigate = useNavigate();
  function navigateBlog() {
    navigate("/blog");
  }

  function navigateHome() {
    navigate("/");
  }
  /*--------------------------------------------------------------------------------------------------------*/
  return (
    <Navbar
      expand="lg"
      style={{ background: "#011E44" }}
      className="navbar-dark"
    >
      <Container>
        <Navbar.Brand
          href="#home"
          style={{
            fontFamily: "Great Vibes",
            fontSize: "22px",
            display: "flex",
            justifyContent: "center",
          }}
        ></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link onClick={navigateHome} href="#home">
              Home
            </Nav.Link>
            <Nav.Link onClick={navigateHome} href="#scheduling">
              Agendamento
            </Nav.Link>
            <Nav.Link onClick={navigateHome} href="#depositions">
              Depoimentos
            </Nav.Link>
            <Nav.Link onClick={navigateHome} href="#contacts">
              Contatos
            </Nav.Link>
            <Nav.Link onClick={navigateBlog}>Blog</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default NavBar;
