import React from "react";
import NavBar from "../../../components/navBar";
import { Title1 } from "../../../Styles";

function BlogContent() {
  return (
    <container className="d-flex flex-column min-vh-100">
      <header
        style={{ position: "sticky", top: "0", zIndex: "1", opacity: 0.94 }}
      >
        <NavBar></NavBar>
      </header>

      <main className="container">
        <div className="row">
          <Title1 className="col text display-5 text-center py-5">
            Autoestima
          </Title1>
        </div>
        <div className="row">
          <div className="col-11 mx-auto mb-5" style={{ textAlign: "justify" }}>
            <p className="col-12 text fs-5">
              É a avaliação que uma pessoa faz de si próprio, do seu valor e sua
              importância, ou seja, é você estar satisfeito com quem é, confiar
              em si mesmo e reconhecer seu valor. É o que uma pessoa pensa a
              respeito dela mesma.<br></br>
              <br></br> Porém a forma como uma pessoa se enxerga é influenciada
              a diversos fatores como:<br></br>
              <br></br>
              <ul>
                <li>Relacionamentos com pais, amigos, professores, etc;</li>
                <li>Fracassos e sucessos ao longo da vida;</li>
                <li>Emoções;</li>
                <li>Pensamentos;</li>
                <li>Formas de enfrentar os problemas;</li>
                <li>O que as pessoas falam a seu respeito;</li>
                <li>Amor e rejeição percebidos;</li>
              </ul>
              <br></br>
              Sendo assim, a forma como uma pessoa se enxerga é resultado da sua
              história de vida, na qual foi criada uma imagem de si que pode ser
              real ou estar distorcida da realidade.
            </p>
            <Title1 className="col-12 text fs-3 pt-5 pb-4">
              A autoestima é composta por 4 pilares:
            </Title1>
            <p className="col-12 text fs-5">
              <strong style={{ color: "#011E44" }}>Autoconceito:</strong> É o
              que uma pessoa pensa de si mesmo através de uma avaliação das suas
              qualidades e defeitos. São as conclusões que uma pessoa tira sobre
              si. Essa visão de si vai refletir a maneira como ela se trata.
              Pode ser que a forma como uma pessoa se vê esteja distorcida da
              realidade ou amparada em padrões irrealistas.
              <br></br>
              <br></br>
              <strong style={{ color: "#011E44" }}>Autoimagem:</strong> Se
              refere aos aspectos corporais e de estética. É o valor que uma
              pessoa atribui a si quando olha no espelho. Relacionada a
              autoimagem. Pode ser fortemente influenciada por padrões estéticos
              externos e estar distorcida em função disso.
              <br></br>
              <br></br>
              <strong style={{ color: "#011E44" }}>Autorreforço:</strong> Se
              refere ao quanto uma pessoa acredita que é merecedora e se reforça
              pelo que foi capaz de fazer, independentemente do tamanho do feito
              realizado. É reconhecer o próprio esforço e dedicação, sentindo-se
              merecedor e se premiar ou se recompensar por isso. Relacionada a
              capacidade de reconhecer os feitos.
              <br></br>
              <br></br>
              <strong style={{ color: "#011E44" }}>Autoeficácia:</strong> Se
              refere a percepção de uma pessoa sobre a sua própria capacidade,
              ou seja, o quanto uma pessoa confia em si e nas suas capacidades.
              Uma percepção de que se é capaz.
            </p>
          </div>
        </div>
      </main>

      <footer
        className="mt-5 mt-lg-1 pb-1 pt-3"
        style={{ backgroundColor: "#011E44" }}
      >
        <p className="text text-light text-center" style={{ fontSize: "10px" }}>
          Copyright © 2024 – BRUNA CORREIA PSICÓLOGA | Todos os direitos
          reservados.
        </p>
      </footer>
    </container>
  );
}

export default BlogContent;
