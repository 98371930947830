import React from "react";
import Card from "react-bootstrap/Card";

function Card1({ title, imageCard }) {
  return (
    <Card className="card">
      <img className="imageCard" src={imageCard} alt="imagem-do-card" />
      <Card.Body className="d-flex align-items-center">
        <Card.Title
          id="contentCard"
          className="text-xl-center mb-xl-2 text-muted mt-xl-4"
        >
          {title}
        </Card.Title>
      </Card.Body>

      <style jsx>{`
        .card {
          height: 100%;
          border: 2px solid #d2b978;
        }

        .imageCard {
          height: 50%;
          position: absolute;
          top: -25%;
          left: 25%;
        }

        @media only screen and (max-width: 1200px) {
          .card {
            height: 130px;
            display: flex;
            justify-content: center;
          }

          .imageCard {
            height: 80%;
            position: absolute;
            top: 10%;
            left: -50px;
          }

          #contentCard {
            margin-left: 8%;
          }
        }
      `}</style>
    </Card>
  );
}

export default Card1;
