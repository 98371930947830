import React from "react";
import NavBar from "../../../components/navBar";
import { Title1 } from "../../../Styles";

function BlogContent() {
  return (
    <container className="d-flex flex-column min-vh-100">
      <header
        style={{ position: "sticky", top: "0", zIndex: "1", opacity: 0.94 }}
      >
        <NavBar></NavBar>
      </header>

      <main className="container">
        <div className="row">
          <Title1 className="col text display-5 text-center py-5">
            Transtorno Obsessivo Compulsivo
          </Title1>
        </div>
        <div className="row">
          <div className="col-11 mx-auto mb-5" style={{ textAlign: "justify" }}>
            <p className="col-12 text fs-5">
              A principal característica do Transtorno Obsessivo Compulsivo
              (TOC) é a presença de obsessões e compulsões.<br></br>
              <br></br> As obsessões são pensamentos ou impulsos que atuam de
              forma intrusiva, persistente e repetitiva, na mente das pessoas
              com TOC. Podem aparecer de diversas formas, como frases, palavras,
              imagens, cenas, entre outros. Seus conteúdos geralmente podem ser
              percebidos como impróprios ou estranhos, podendo assim gerar
              sentimento de culpa, medo, desprazer e até nojo. Os pensamentos
              obsessivos podem ser de diferentes tipos: dúvidas e necessidades
              de ter certeza, preocupações excessivas com sujeira,
              contaminações, preocupação excessiva com alinhamento de objetos,
              sequencia, ordem e simetria, pensamentos ou imagens violentas,
              pensamentos impróprios e indesejáveis relacionados a sexo,
              religião, superstições, entre outros.<br></br>
              <br></br> O indivíduo com TOC tenta reprimir, afastar e/ou
              controlar esses pensamentos realizando rituais, compulsões,
              evitações ou simplesmente tentando não pensar neles, o que na
              maioria das vezes não é eficaz, e desta forma a pessoa acaba se
              sentindo cada vez mais incomodada.<br></br>
              <br></br> As compulsões são comportamentos realizados pelos
              indivíduos com TOC na tentativa de se livrar das obsessões ou do
              desconforto provocado por elas. Esses comportamentos podem ser
              diversos, como: verificar fechaduras; confirmar de forma repetida
              o fechamento de objetos como botijão de gás, janelas, chuveiro ou
              torneira, lavar as mãos ou objetos excessivamente, perfeccionismo
              extremo nas atividades realizadas, simetria e organização de
              objetos, ações repetidas (sentar e levantar do lugar; entrar e
              sair), rituais (contar até determinado número ou bater na madeira,
              entre outros).<br></br>
              <br></br> As compulsões ou rituais também podem aparecer como atos
              mentais com a mesma finalidade de dar fim ou neutralizar as
              obsessões, exemplos: substituir pensamentos “ruins” por
              pensamentos “bons”, repetir determinada palavras ou números com o
              objetivo de neutralizar os pensamentos, repetir silenciosamente
              alguma reza, fazer contagens, verificações ou rememorar os fatos
              mentalmente, entre outros.<br></br>
              <br></br> No TOC de início precoce são mais comuns os
              comportamentos repetitivos semelhantes a tiques, como tocar,
              esfregar, dar pancadinhas, piscar os olhos ou olhar fixamente.
              Essas compulsões geralmente não são precedidas por obsessões, mas
              apenas por uma sensação de desconforto ou tensão física na pele,
              músculos, articulações que necessita ser aliviada ou descarregada,
              ou por um sentimento de urgência do tipo “tenho que...” ou “tem
              que...’descrito pelos pacientes como uma agonia que é aliviada com
              a realizações das compulsões.
            </p>
            <Title1 className="col-12 text fs-3 pt-5 pb-4">
              Modelo Cognitivo
            </Title1>
            <p className="col-12 text fs-5">
              O modelo cognitivo propõe que interpretações distorcidas são as
              responsáveis pelo fato de determinados pensamentos intrusivos
              assumirem um significado especial para as pessoas, ativando
              pensamentos automáticos negativos ou catastróficos e emoções
              desagradáveis. Em consequência dessas interpretações, tais
              pensamentos assumem um significado importante, fazendo com que as
              pessoas adotem estratégias para neutraliza-lo.
            </p>
          </div>
        </div>
      </main>

      <footer
        className="mt-5 mt-lg-1 pb-1 pt-3"
        style={{ backgroundColor: "#011E44"}}
      >
        <p className="text text-light text-center" style={{ fontSize: "10px" }}>
          Copyright © 2024 – BRUNA CORREIA PSICÓLOGA | Todos os direitos
          reservados.
        </p>
      </footer>
    </container>
  );
}

export default BlogContent;
