import React from "react";
import NavBar from "../../../components/navBar";
import { Title1 } from "../../../Styles";

function BlogContent() {
  return (
    <container className="d-flex flex-column min-vh-100">
      <header
        style={{ position: "sticky", top: "0", zIndex: "1", opacity: 0.94 }}
      >
        <NavBar></NavBar>
      </header>

      <main className="container">
        <div className="row">
          <Title1 className="col text display-5 text-center py-5">
            Transtorno de Ansiedade Generalizada
          </Title1>
        </div>
        <div className="row">
          <div className="col-11 mx-auto mb-5" style={{ textAlign: "justify" }}>
            <p className="col-12 text fs-5">
              A ansiedade é um sentimento vago e desagradável de medo,
              apreensão, caracterizado por tensão ou desconforto derivado de
              antecipação do perigo, de algo desconhecido ou estranho. O medo e
              a ansiedade são importantes em nossa em vida, mas quando aparecem
              em excesso passamos a chamá-los de sintomas que podem estar
              relacionados a transtornos ansiosos.<br></br>
              <br></br> Os transtornos de ansiedade
              podem causar um efeito negativo significativo no funcionamento
              diário, na trajetória desenvolvimental, na capacidade de
              aprendizagem e nas relações familiares e de amizade.<br></br>
              <br></br> O Transtorno
              de Ansiedade Generalizada (TAG) é um transtorno crônico de
              ansiedade no qual preocupação e ansiedade excessivas estão
              presentes na maioria dos dias e em diversos eventos ou atividades,
              considerando os últimos seis meses. O transtorno está associado a
              sintomas como inquietação ou sensação de estar com os nervos à
              flor da pele, fadiga ou cansaço excessivo, dificuldade em se
              concentrar ou sensações de branco na mente, irritabilidade, tensão
              muscular e alterações do sono.<br></br>
              <br></br> O conteúdo das preocupações pode
              variar, mas normalmente, pessoas com TAG se preocupam com relações
              interpessoais, saúde física, escola, trabalho, finanças, questões
              mundiais e ou questões menores (compromissos diários) e até mesmo
              se preocupam por estarem preocupados. Essas preocupações são
              crônicas, amplas e penetrantes. A ansiedade exacerbada pode levar
              a tremores, contrações, dores musculares, nervosismo ou
              irritabilidade. Muitos indivíduos experimentam sintomas como:
              sudorese, náusea, alterações intestinais e uma resposta de
              sobressalto exagerada.<br></br>
              <br></br> Sentir ansiedade é normal e até benéfico. A
              ansiedade começa a se tornar disfuncional quando o indivíduo passa
              a subestimar a sua capacidade de enfrentamento e segurança, e a
              supervalorizar a probabilidade e gravidade da possível ameaça
              ocorrer, levando-o frequentemente a evitação de certas situações.<br></br>
              <br></br>
              De acordo com a Terapia Cognitiva Comportamental (TCC), a nossa
              interpretação sobre uma situação influencia diretamente no que
              sentimos, em como agimos e nas nossas reações corporais. Muitas
              vezes a experiência intensa e incontrolável da ansiedade faz com o
              que o indivíduo não seja capaz de reconhecer que os pensamentos
              ajudam a manter as emoções.<br></br>
              <br></br> Pessoas com TAG tem dificuldades para
              tolerar incertezas e com isso, acreditam que precisam ter o máximo
              controle das situações, o que os fazem buscar por segurança o
              tempo todo. Não há possibilidade de controlar todas as variáveis e
              a busca incessante e infrutífera por certezas absolutas
              relacionadas ao futuro acaba por gerar mais angustia e ansiedade,
              o que leva a comportamentos de evitação nas quais seria esperado
              uma ação do indivíduo, mesmo sem total garantia do resultado.<br></br>
              <br></br> Na
              maior parte do tempo, pessoas com TAG estão se preocupando com
              possíveis consequências negativas e formas de evitar o pior. Por
              acreditarem que devem se prevenir, essas pessoas tem um foco
              atencional para a ameaça e são capazes de detectar o perigo em
              situações neutras. Posteriormente, começam a pensar esse perigo de
              forma catastrófica e tentam de todas as maneiras controlar a
              situação e evitar o pior. Como isso não é possível, optam por
              evitar ou fugir da situação temida. Contudo, evitar ou fugir acaba
              mantendo-os no ciclo vicioso da ansiedade.
              </p>
          </div>
        </div>
      </main>

      <footer
        className="mt-5 mt-lg-1 pb-1 pt-3"
        style={{ backgroundColor: "#011E44" }}
      >
        <p className="text text-light text-center" style={{ fontSize: "10px" }}>
          Copyright © 2024 – BRUNA CORREIA PSICÓLOGA | Todos os direitos
          reservados.
        </p>
      </footer>
    </container>
  );
}

export default BlogContent;
