import styled from "styled-components";
import Backgound1 from "./assets/background-1.png";

export const DivSection1 = styled.div`
  background: url("${Backgound1}");
  background-size: cover;

  @media only screen and (max-width: 992px) {
    background: none;
  }
`;

export const Title = styled.p`
  color: #d2b978;
  font-family: Libre Baskerville;
  margin-left: -40%;
  font-size: 4vw;

  @media only screen and (min-width: 992px){
    font-size: 35px;
}
`;

export const Title1 = styled.p`
  color: #d2b978;
  font-family: Libre Baskerville;
`;

export const Text1 = styled.p`
  font-size: 20px;
`;

export const ButtonZap = styled.a`
  border: none;
  border-radius: 100px;
  background-color: #198754;
  position: fixed;
  height: 63px;
  width: 63px;
  bottom: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  && img {
    margin-right: 5%;
    height: 70px;
    transition: 0.2s;
  }

  &:hover {
    img {
      height: 80px;
    }
  }

  &:active {
    background-color: #157347;
    transition: 0.2s;
  }
`;

export const ButtonSection7 = styled.a`
  width: 100%;
  background-color: #011e44;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.2s;

  &:hover {
    background-color: #011838;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: #001126;
    color: #fff;
  }

  @media only screen and (max-width: 992px) {
    width: 75vw;
  }
`;
