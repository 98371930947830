import React from "react";
import NavBar from "../../../components/navBar";
import { Title1 } from "../../../Styles";

function BlogContent() {
  return (
    <container className="d-flex flex-column min-vh-100">
      <header
        style={{ position: "sticky", top: "0", zIndex: "1", opacity: 0.94 }}
      >
        <NavBar></NavBar>
      </header>

      <main className="container flex-grow-1">
        <div className="row">
          <Title1 className="col text display-5 text-center py-5">
            Terapia Cognitivo Comportamental
          </Title1>
        </div>
        <div className="row">
          <div className="col-11 mx-auto mb-5 " style={{ textAlign: "justify" }}>
            <p className="col-12 text fs-5 ">
              Terapia Cognitivo Comportamental é uma abordagem psicológica,
              baseada em evidências, que é focada no problema e dirigida a ação,
              utilizada para o tratamento das psicopatologias e dificuldades dos
              pacientes.<br></br>
              <br></br> A premissa principal da TCC é que as distorções de
              pensamentos e comportamentos mal adaptativos desempenham um papel
              no desenvolvimento e na manutenção dos transtornos psicológicos, e
              que os sintomas e o sofrimento associado podem ser reduzidos pelo
              ensino de novas habilidades de processamento de informações e
              mecanismos de enfrentamento.<br></br>
              <br></br> Sendo assim, trabalhamos com os
              pensamentos, emoções e comportamentos que se encontram
              disfuncionais e treinamos habilidades para reduzir o sofrimento do
              paciente. 
            </p>
          </div>
        </div>
      </main>

      <footer
        className="mt-5 mt-lg-1 pb-1 pt-3"
        style={{ backgroundColor: "#011E44"}}
      >
        <p className="text text-light text-center" style={{ fontSize: "10px" }}>
          Copyright © 2024 – BRUNA CORREIA PSICÓLOGA | Todos os direitos
          reservados.
        </p>
      </footer>
    </container>
  );
}

export default BlogContent;
