import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import NavBar from "../../components/navBar";
import ImgPrincipal from "../../assets/foto-bruna.png";
import FotoBruna1 from "../../assets/foto-bruna-1.png";
import FotoBruna2 from "../../assets/foto-bruna-3.png";
import LogoZap from "../../assets/icon-whatsapp.png";
import LogoZap1 from "../../assets/icon-zap-1.png";
import LogoInstagram from "../../assets/icon-instagram.png";
import LogoEmail from "../../assets/icon-email.png";
import imageCard1 from "../../assets/template-1.png";
import imageCard2 from "../../assets/template-2.png";
import imageCard3 from "../../assets/template-3.png";
import imageCard4 from "../../assets/template-4.png";
import Card1 from "../../components/card1";
import Card2 from "../../components/card2";
import Card3 from "../../components/card3";
import {
  ButtonSection7,
  ButtonZap,
  DivSection1,
  Title,
  Title1,
} from "../../Styles";
import InstaFeed from "../../components/Insta";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  return (
    <container style={{ maxWidth: "100vw" }}>
      <header
        style={{ position: "sticky", top: "0", zIndex: "1", opacity: 0.94 }}
      >
        <NavBar></NavBar>
      </header>
      {/********************************Seção 1*****************************************/}
      <main>
        <section-1 id="home">
          <DivSection1
            className="d-lg-flex flex-row-reverse align-items-center"
            style={{}}
          >
            <img src={ImgPrincipal} alt="foto-bruna-correia-psicóloga" className="col-lg-6 img-fluid text-end" />
            <div className="col-lg-6 pt-5 pt-lg-0">
              <h1
                className="text-center display-1"
                style={{
                  fontFamily: "Great Vibes",
                  color: "#011E44",
                  lineHeight: "0.8em",
                }}
                data-aos="zoom-out"
                data-aos-duration="1500"
              >
                Bruna Correia
              </h1>
              <h2 className="display-6 mb-3 text-center text-nowrap" style={{letterSpacing: "-1px"}} data-aos="fade">
                - Psicóloga e Neuropsicóloga -
              </h2>
              <p className="pb-5 text-center" data-aos="fade">
                Especialista em Terapia Cognitivo Comportamental - CRP 04/47563
              </p>
            </div>
          </DivSection1>
        </section-1>
        {/********************************Seção 2*****************************************/}
        <section-2>
          <div className="py-5" style={{ backgroundColor: "#011E44" }}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <Title1 className="text text-center display-6 py-md-3">
                    A vida tem sido assim para você?
                  </Title1>
                </div>
              </div>
            </div>
            <div className="container-fluid py-xl-4 my-xl-4">
              <div className="row justify-content-center gap-1">
                <div className="col-10 col-xl-2 mb-3" data-aos="fade-up">
                  <Card1
                    title="Dificuldade de regular as emoções?"
                    imageCard={imageCard1}
                  ></Card1>
                </div>
                <div className="col-10 col-xl-2 mb-3" data-aos="fade-up">
                  <Card1
                    title="Constante insatisfação com você e autoestima prejudicada?"
                    imageCard={imageCard2}
                  ></Card1>
                </div>
                <div className="col-10 col-xl-2 mb-3" data-aos="fade-up">
                  <Card1
                    title="Sofrimento relacionado a transtornos como ansiedade e depressão?"
                    imageCard={imageCard3}
                  ></Card1>
                </div>
                <div className="col-10 col-xl-2 mb-3" data-aos="fade-up">
                  <Card1
                    title="Sempre está insegura e sem confiança para lidar com as demandas do dia a dia?"
                    imageCard={imageCard4}
                  ></Card1>
                </div>
              </div>
            </div>
            <div
              className="d-grid col-md-4 col-8 mx-auto my-2"
              data-aos="fade"
              data-aos-duration="1500"
            >
              <a
                className="btn btn-success fs-5"
                href="https://api.whatsapp.com/send?1=pt_BR&phone=5531971220250"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-whatsapp fa-7x fs-5 me-2"></i>
                EU QUERO AJUDA
              </a>
            </div>
          </div>
        </section-2>
        {/********************************Seção 3*****************************************/}
        <section-3>
          <div className="container-fluid py-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <Title1 className="text text-center display-6 py-md-3">
                    “A terapia é para qualquer pessoa que está insatisfeita com
                    a forma com que sente, pensa e age e deseja mudar...”
                  </Title1>
                </div>
              </div>
            </div>
            <div className="row gap-4 justify-content-center">
              <div className="col-12 col-lg-5">
                <Card2 title="O que é a terapia?">
                  A Terapia é uma intervenção psicológica que trata as
                  dificuldades emocionais, cognitivas e comportamentais de uma
                  pessoa.<br></br>
                  <br></br> O objetivos da terapia são:<br></br> - Reduzir o
                  mal-estar e/ou sofrimento da pessoa;<br></br> - Interverir em
                  comportamentos desadaptativos;<br></br> - Aumentar as
                  estratégias de enfrentamento e adaptação.<br></br>
                  <br></br> A Terapia deve ser considerada também como um
                  tratamento biológico, que funciona por meio da
                  neuroplasticidade, allterando os padrões de funcionamento de
                  uma pessoa.
                </Card2>
              </div>
              <div className="col-12 col-lg-5">
                <Card2 title="Terapia Cognitivo-Comportamental">
                  A Terapia Cognitivo Comportamental (TCC) é uma abordagem de
                  intervenção psicológica baseadas em evidências. Na TCC iremos
                  trabalhar pensamentos, emoções e comportamentos que se
                  encontram disfuncionais.<br></br>
                  <br></br> Eu participo ativamente das sessões ajudando você a
                  superar os desafios. As intervenções serão baseadas nas
                  melhores estudos e evidências para favorecer a sua evolução.
                  <br></br>
                  <br></br>
                  Você irá entender como você funciona e terá as ferramentas
                  necessárias para regular suas emoções e gerenciar as situações
                  da sua vida. Na terapia iremos trabalhar com os seus
                  pensamentos, emoções e comportamentos e treinaremos
                  habilidades para favorecer seu bem-estar e reduzir o
                  sofrimento.
                </Card2>
              </div>
            </div>
          </div>
        </section-3>
        {/********************************Seção 4*****************************************/}
        <section-4 id="scheduling">
          <div className="py-2" style={{ backgroundColor: "#011E44" }}>
            <div className="container py-5">
              <div className="row align-items-center">
                <div className="container col-11 col-lg-6">
                  <h5 className="display-6 text-light text-center">
                    Como funciona as sessões?
                  </h5>
                  <p className="text-light fs-5 py-3 text-center">
                    As sessões são online. O atendimento online funciona de
                    forma muito semelhante ao atendimento presencial.<br></br>
                    <br></br> Faremos uma sessão inicial de avaliação, onde irei
                    buscar entender o que você esta sentindo, conhecer sua
                    história de vida e entender como e se posso ajudar você.
                    <br></br>
                    <br></br> A partir da segunda sessão, iremos trabalhar
                    estratégias para que você consiga lidar com as situações.
                    Iremos trabalhar também a relação entre pensamentos, emoções
                    e comportamentos para que você possa se sentir bem no dia a
                    dia.
                  </p>
                  <div className="d-grid col-md-7 mx-auto">
                    <a
                      className="btn btn-success fs-5"
                      href="https://api.whatsapp.com/send?1=pt_BR&phone=5531971220250"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-whatsapp fa-7x fs-5 me-2"></i>
                      AGENDAR ATENDIMENTO
                    </a>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row justify-content-center">
                    <div className="col-7">
                      <img
                        className="img-fluid img-thumbnail d-none d-lg-grid"
                        style={{ transform: "rotate(15deg)" }}
                        src={FotoBruna1}
                        alt="foto-bruna-correia-psicologa"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section-4>

        {/********************************Seção 5*****************************************/}
        <section-5>
          <div className="d-flex flex-row align-items-center">
            <img
              src={FotoBruna2}
              alt="foto-bruna-correia-psicologa"
              className="col-lg-4 col-7 img h-50 img-lg-fluid"
              data-aos="fade"
            />
            <div className="col-lg-8" data-aos="fade">
              <div className="col-lg-10">
                <Title className="text py-4 w-75 w-md-100 ms-1 text-center text-lg-start">
                  É possível aprender a lidar com as suas emoções e gerenciar a
                  sua vida!
                </Title>

                <p
                  className="text fs-5 d-none d-lg-grid"
                  style={{ textAlign: "justify" }}
                >
                  Prazer, eu sou a Bruna!<br></br>
                  <br></br> Sou Psicóloga pela Universidade Federal de São João
                  del-Rei (UFSJ), Neuropsicóloga pela PUC-Minas e Especialista
                  em Terapia Cognitivo Comportamental pela PUC-Minas.
                  Atualmente, sou Pós-graduanda em Psicopatologia. Além disso,
                  possuo diversas formações complementares.<br></br>
                  <br></br> Acredito que por meio da psicoterapia e processo de
                  autoconhecimento, o acompanhamento psicológico pode ajudar as
                  pessoas a entenderem seus padrões de pensamentos, emoções,
                  comportamentos e suas relações sociais e a partir disso
                  modificar o que se encontra disfuncional na sua vida.
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="container">
              <p className="d-lg-none  text-center my-5 fs-4">
                <strong>Prazer, eu sou a Bruna!</strong>
                <br></br>
                <br></br> Sou Psicóloga pela Universidade Federal de São João
                del-Rei (UFSJ), Neuropsicóloga pela PUC-Minas e Especialista em
                Terapia Cognitivo Comportamental pela PUC-Minas. Atualmente, sou
                Pós-graduanda em Psicopatologia. Além disso, possuo diversas
                formações complementares.<br></br>
                <br></br> Acredito que por meio da psicoterapia e processo de
                autoconhecimento, o acompanhamento psicológico pode ajudar as
                pessoas a entenderem seus padrões de pensamentos, emoções,
                comportamentos e suas relações sociais e a partir disso
                modificar o que se encontra disfuncional na sua vida.
              </p>
            </div>
          </div>
        </section-5>
        {/********************************Seção 6*****************************************/}
        <section-6 id="depositions">
          <div className="py-5" style={{ backgroundColor: "#011E44" }}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-10 col-md-8">
                  <p
                    className="text text-light text-center py-5"
                    style={{
                      fontFamily: "Libre Baskerville",
                      fontSize: "30px",
                    }}
                  >
                    Veja o que diz quem já esteve em tratamento comigo…
                  </p>
                </div>
              </div>
            </div>
            <div className="container-fluid py-xl-4 my-xl-4">
              <div className="row justify-content-center gap-1">
                <div className="col-10 col-md-4 col-xl-2 mb-3">
                  <Card3
                    name="Daniel"
                    content="A Bruna é uma pessoa simpática, parece ser uma ótima profissional e 
                    na primeira sessão procurou definir meu perfil e como funciono para 
                    trabalharmos em cima de meus obstáculos."
                  ></Card3>
                </div>
                <div className="col-10 col-md-4 col-xl-2 mb-3">
                  <Card3
                    name="Ana"
                    content="Excelente psicóloga, combina seu enorme conhecimento técnico
                     com sua empatia."
                  ></Card3>
                </div>
                <div className="col-10 col-md-4 col-xl-2 mb-3">
                  <Card3
                    name="Wellingthon"
                    content="Excelente profissional. Minha primeira sessão na vida e ela 
                    conseguiu me deixar à vontade, com confiança e liberdade para
                     conversar. Recomendo!"
                  ></Card3>
                </div>
                <div className="col-10 col-md-4 col-xl-2 mb-3">
                  <Card3
                    name="Jessica"
                    content="Bruna é uma profissional muito competente e compreensível !
                     Ela tem me ajudado muito nos muitos meses, sinto- me à vontade ao
                      conversar com ela. Super recomendo!!"
                  ></Card3>
                </div>
              </div>
            </div>
          </div>
        </section-6>

        {/********************************Seção 7*****************************************/}

        <section-7 id="contacts">
          <div className="container p-xl-5 mt-5">
            <div className="row justify-content-between gap-5 px-xl-5 px-3">
              <div className="col-xl-5 col-12 ">
                <div className="container">
                  <Title1 className="display-6">
                    Ainda ficou com alguma dúvida?
                  </Title1>
                  <p className="fs-5" style={{ marginTop: "-15px" }}>
                    ENTRE EM CONTATO.
                  </p>
                </div>
                <div className="container d-grid ps-5 px-xl-0 py-3">
                  <ButtonSection7
                    className="ps-5 ms-lg-5 mb-3"
                    href="mailto:brunacorreia.psicologa@gmail.com"
                    rel="noopener noreferrer"
                   
                  >
                    <div className="row align-items-center">
                      <img
                        className="col"
                        src={LogoEmail}
                        alt="logo-whatsapp"
                        style={{
                          width: "135px",
                          position: "absolute",
                          marginLeft: "-120px",
                        }}
                      />
                      <div className="col text text-start text-light pe-5">
                        <h4 className="fs-5">E-mail</h4>
                        <p style={{ fontSize: "10px", lineHeight: "0.2em" }}>
                          Encaminhe sua dúvida para
                        </p>
                        <p style={{fontSize: "13px", lineHeight: "0.5em" }}>
                          brunacorreia.psicologa@gmail.com
                        </p>
                      </div>
                    </div>
                  </ButtonSection7>
                  <ButtonSection7
                    className="ps-5 ms-lg-5 mb-3"
                    href="https://api.whatsapp.com/send?1=pt_BR&phone=5531971220250"
                    target="_blank"
                    rel="noopener noreferrer"
                    
                  >
                    <div className="row align-items-center">
                      <img
                        className="col"
                        src={LogoZap}
                        alt="logo-whatsapp"
                        style={{
                          width: "135px",
                          position: "absolute",
                          marginLeft: "-120px",
                        }}
                      />
                      <div className="col text text-start text-light pe-5">
                        <h4 className="fs-5">WhatsApp</h4>
                        <p style={{ fontSize: "10px", lineHeight: "0.2em" }}>
                          Entre em contato comigo
                        </p>
                        <p className="fs-6" style={{ lineHeight: "0.5em" }}>(31) 7122 0250</p>
                      </div>
                    </div>
                  </ButtonSection7>
                </div>
              </div>
              <div className="col-xl-5 col-12">
                <Title1 className="container display-6 text-xl-end">
                  Acompanhe meu conteúdo no Instagram!
                </Title1>
                <InstaFeed></InstaFeed>
                <div className="container d-grid">
                  <ButtonSection7
                    className="w-100 px-3 text-light fs-5 text-center"
                    href="https://www.instagram.com/brunacorreiapsi/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img me-3"
                      src={LogoInstagram}
                      alt="logo-instagram"
                      style={{ width: "35px" }}
                    />
                    @BRUNACORREIAPSI
                  </ButtonSection7>
                </div>
              </div>
            </div>
          </div>
        </section-7>
      </main>
      <footer
        className="mt-5 mt-lg-1 pb-1 pt-3"
        style={{ backgroundColor: "#011E44" }}
      >
        <p className="text text-light text-center" style={{ fontSize: "10px" }}>
          Copyright © 2024 – BRUNA CORREIA PSICÓLOGA | Todos os direitos
          reservados.
        </p>
      </footer>
      <ButtonZap
        href="https://api.whatsapp.com/send?1=pt_BR&phone=5531971220250"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={LogoZap1} alt="botão-whatsapp" />
      </ButtonZap>
    </container>
  );
}

export default App;
