import React, { useState, useEffect } from "react";
import axios from "axios";

function InstaFeed() {
  const [feedList, setFeedList] = useState([]);

  async function getInstaFeed() {
    const token = process.env.REACT_APP_INSTA_TOKEN;
    try {
      const response = await axios.get(
        `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${token}`
      );
      setFeedList(response.data.data);
    } catch (error) {
      console.error("Error fetching Instagram feed", error);
    }
  }

  useEffect(() => {
    getInstaFeed();
  }, []);

 

  return (
    <div className="container my-2">
      <div className="row">
        {feedList.slice(0, 6).map((item) => (
          <div className="col-4 mb-4" key={item.id}>
            {item.media_type === "IMAGE" && (
              <img
                className="img-fluid rounded"
                style={{ width: "100%", height: "100%", objectFit: "cover", aspectRatio: "1/1", border: "1px solid #6c757d" }}
                src={item.media_url}
                alt={item.caption}
              />
            )}
             {item.media_type === "CAROUSEL_ALBUM" && (
              <img
                className="img-fluid rounded"
                style={{ width: "100%", height: "100%", objectFit: "cover", aspectRatio: "1/1", border: "1px solid #6c757d" }}
                src={item.media_url}
                alt={item.caption}
              />
            )}
            {item.media_type === "VIDEO" && (
              <video
                className="img-fluid rounded"
                style={{ width: "100%", height: "100%", objectFit: "cover", aspectRatio: "1/1" }}
                controls
                src={item.media_url}
                autoPlay
                muted
                loop
                playsInline
                controlsList="nodownload"
              ></video>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
export default InstaFeed;
