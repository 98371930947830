import React from "react";
import NavBar from "../../../components/navBar";
import { Title1 } from "../../../Styles";

function BlogContent() {
  return (
    <container className="d-flex flex-column min-vh-100">
      <header
        style={{ position: "sticky", top: "0", zIndex: "1", opacity: 0.94 }}
      >
        <NavBar></NavBar>
      </header>

      <main className="container">
        <div className="row">
          <Title1 className="col text display-5 text-center py-5">
            Transtorno de Ansiedade Social
          </Title1>
        </div>
        <div className="row">
          <div className="col-11 mx-auto mb-5" style={{ textAlign: "justify" }}>
            <p className="col-12 text fs-5">
              A ansiedade é um sentimento vago e desagradável de medo,
              apreensão, caracterizado por tensão ou desconforto derivado de
              antecipação do perigo, de algo desconhecido ou estranho. O medo e
              a ansiedade são importantes em nossa em vida, mas quando aparecem
              em excesso passamos a chama-los de sintomas que podem estar
              relacionados a transtornos ansiosos.<br></br>
              <br></br> Os transtornos de ansiedade podem causar um efeito
              negativo significativo no funcionamento diário, na trajetória
              desenvolvimental, na capacidade de aprendizagem e nas relações
              familiares e de amizade.<br></br>
              <br></br> O principal componente do transtorno de ansiedade social
              é o medo de ser avaliado negativamente em situações sociais. A
              pessoa com TAS tem medo de agir ou mostrar sintomas de ansiedade,
              de maneira que lhe seja embaraçosa ou humilhante e procura evitar
              situações ou as suporta com bastante desconforto.<br></br>
              <br></br> Sentir-se ansioso em situações é uma experiência
              universal e necessária. O problema é quando a ansiedade se torna
              um impedimento para se relacionar, atrapalhando significativamente
              a vida, trazendo intenso sofrimento.<br></br>
              <br></br> Pessoas com TAS costumam pensar que se outras pessoas se
              aproximarem, elas poderão critica-las e rejeita-las e isso gera
              uma preocupação grande de disfarçar os sintomas de ansiedade.
              <br></br>
              <br></br> De acordo com a Terapia Cognitiva Comportamental (TCC),
              a nossa interpretação sobre uma situação influencia diretamente no
              que sentimos, em como agimos e nas nossas reações corporais. Uma
              pessoa que possui ansiedade social tende a ter pensamentos
              negativistas e catastróficos que provocam reações de ansiedade,
              fazendo com que a pessoa passe a evitar ou enfrente com grande
              incomodo as situações sociais. Ao estar exposta a situações
              sociais ativa sintomas emocionais, fisiológicos, cognitivos e
              comportamentais. Nessas situações, a pessoa passa a ter
              pensamentos negativos sobre si e sobre o que as pessoas acharão a
              seu respeito.<br></br>
              <br></br> A evitação dos eventos sociais tem grande importância na
              manutenção do transtorno. A pessoa se sente aliviada por não
              participar daquele evento, o que a curto prazo é uma boa
              estratégia para diminuir a ansiedade. Contudo, a longo prazo, a
              pessoa não desenvolve repertórios para poder lidar com aquela
              situação e acaba perpetuando o problema. Além disso, a ideia de
              que eventos sociais são um risco é mantida, uma vez que a pessoa
              não coloca o pensamento a prova.<br></br>
              <br></br> Uma outra característica da Ansiedade Social é o
              processamento pré-evento, isto é, a expectativa sobre algum evento
              social, sobre o que vai ou pode acontecer. Diante da iminência de
              alguma situação social, podem surgir lembranças de eventos
              anteriores entendidas como falhas, pensamentos sobre incapacidade
              de lidar com a situação e de que poderá ser rejeitado ou passar
              vergonha. Diante disso, a pessoa começa a fazer um rastreamento
              das possibilidades que o evento tem de ser uma experiência
              desagradável, enquanto terá dificuldades de pensar no que pode dar
              certo. Dessa forma, é possível que a pessoa gere uma ansiedade
              antecipatória, podendo apresentar as mesmas reações emocionais e
              fisiológicas que teria se o que pensou de fato ocorresse.<br></br>
              <br></br> Outro aspecto que merece destaque é a atenção
              autofocada, que é uma atenção voltada para si, sobre o que está
              pensando, sentindo e preocupação sobre como está sendo avaliado
              pelas pessoas a sua volta. Isso pode desencadear sintomas de
              ansiedade.<br></br>
              <br></br> O processamento pós evento também precisa ser
              considerado. Consiste em uma retrospectiva da situação, buscando
              encontrar falhas. Isso tende a perpetuar a avaliação negativa de
              si, uma vez que a atenção fica concentrada no que pode ter dado
              errado em um evento e desconsidera possíveis aspectos positivos.
            </p>
          </div>
        </div>
      </main>

      <footer
        className="mt-5 mt-lg-1 pb-1 pt-3"
        style={{ backgroundColor: "#011E44" }}
      >
        <p className="text text-light text-center" style={{ fontSize: "10px" }}>
          Copyright © 2024 – BRUNA CORREIA PSICÓLOGA | Todos os direitos
          reservados.
        </p>
      </footer>
    </container>
  );
}

export default BlogContent;
