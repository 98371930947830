import React from "react";
import NavBar from "../../../components/navBar";
import { Title1 } from "../../../Styles";
import FotoBruna2 from "../../../assets/foto-bruna-2.png";

function BlogContent() {
  return (
    <container className="d-flex flex-column min-vh-100">
      <header
        style={{ position: "sticky", top: "0", zIndex: "1", opacity: 0.94 }}
      >
        <NavBar></NavBar>
      </header>

      <main className="container">
        <div className="row">
          <Title1 className="col text display-5 text-center py-5">
            Como funciona as sessões?
          </Title1>
        </div>
        <div className="row">
          <div className="col-11 col-md-5 mx-auto mb-5" style={{ textAlign: "justify" }}>
            <p className="fs-5 pt-md-5 mt-md-3">
              <strong style={{ color: "#011E44" }}>
                O atendimento online funciona da seguinte forma:
              </strong>
              <br />
              <br />
              Faremos uma sessão inicial de avaliação, onde irei buscar entender
              o que você está sentindo, conhecer sua história de vida e entender
              como e se posso ajudar você. A partir da segunda sessão, iremos
              trabalhar estratégias para que você consiga lidar com as situações.
              Iremos trabalhar também a relação entre pensamentos, emoções e
              comportamentos para que você possa se sentir bem no dia a dia.
            </p>
          </div>
          <div className="col-12 col-md-6 mx-auto mb-5">
            <img
              className="img-fluid"
              src={FotoBruna2}
              alt="foto-bruna-atendimento-online"
              style={{ maxWidth: '100%', height: 'auto'}}
            />
          </div>
        </div>
      </main>

      <footer
        className="mt-5 mt-lg-1 pb-1 pt-3"
        style={{ backgroundColor: "#011E44" }}
      >
        <p className="text text-light text-center" style={{ fontSize: "10px" }}>
          Copyright © 2024 – BRUNA CORREIA PSICÓLOGA | Todos os direitos
          reservados.
        </p>
      </footer>
    </container>
  );
}

export default BlogContent;
