import Card from 'react-bootstrap/Card';

function Card2({title, children}) {
  return (
    <Card className='card text-center h-100 text-light'>
      <Card.Header className='fs-4 py-3' style={{backgroundColor: "#011E44"}}>{title}</Card.Header>
      <Card.Body style={{background: `linear-gradient(to right, #937335, #A48A4B, #745125)`}}>
        <blockquote className="blockquote mb-0 fs-5 ">
          <p>
          {children}
          </p>
          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default Card2;