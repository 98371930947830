import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Pages/Home";
import Blog from "./Pages/Blog";
import BlogContent1 from "./Pages/BlogContent/Content1";
import BlogContent2 from "./Pages/BlogContent/Content2";
import BlogContent3 from "./Pages/BlogContent/Content3";
import BlogContent4 from "./Pages/BlogContent/Content4";
import BlogContent5 from "./Pages/BlogContent/Content5";
import BlogContent6 from "./Pages/BlogContent/Content6";
import BlogContent7 from "./Pages/BlogContent/Content7";


function MyRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/blogContent1" element={<BlogContent1/>} />
        <Route path="/blogContent2" element={<BlogContent2/>} />
        <Route path="/blogContent3" element={<BlogContent3/>} />
        <Route path="/blogContent4" element={<BlogContent4/>} />
        <Route path="/blogContent5" element={<BlogContent5/>} />
        <Route path="/blogContent6" element={<BlogContent6/>} />
        <Route path="/blogContent7" element={<BlogContent7/>} />
        </Routes>
    </Router>
  );
}

export default MyRoutes;